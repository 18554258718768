<template>
  <div class="bac">
    <UserCardMini/>
    <div class="listBac">
      <div class="info_card_top">
        <div>
          <a>参与的项目</a>
          <i></i>
        </div>
        <div style="margin-right: 7.55rem;">
          <a>已完结</a>
          <i></i>
        </div>
      </div>
      <div class="info_card_medium">
        <div>提交日期</div>
        <i class="fa fa-angle-down" style="font-size:.24rem"></i>
        <div>项目进度</div>
        <i class="fa fa-angle-down" style="font-size:.24rem"></i>
        <div>项目操作</div>
      </div>
      <div class="list">
        <img src="../../../../images/resources.png" alt="" v-show="false">
        <div v-for="(item,i) in this.Info">
          <div class="bottom_show">
            <img :src="item.pic" alt="" style="width: 2.8rem; height: 1.9rem">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import UserCardMini from "@/components/UserCardMini";
export default {
  name: "ProjectAttend",
  data(){
    return{
      Info:'',
    }
  },
  mounted() {
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'get',
        url: '/getJoinedProjects',
        data: ''
      }).then(res => {
        this.Info = res.data;
      })
    }
  },
  components:{
    UserCardMini
  }
}
</script>

<style scoped>
.bac{
  width: 19.2rem;
  padding-left: 2.98rem;
  padding-top: .5rem;
  background-color: #f5f5f5;
}

.listBac{
  width: 14.6rem;
  height: 4.6rem;
  margin-left: .7rem;
  background-color: #ffffff;
}

.listBac .info_card_top{
  width: 12.3rem;
  height: 1rem;
  background-color: #ffffff;
  margin-left: .5rem;
  border: .01rem solid transparent;
  border-bottom: .02rem solid #acaeab;
  margin-bottom: .15rem;
  padding-left: .5rem;

}

.listBac .info_card_top div{
  float: left;
  width: 1.2rem;
  height: 1rem;
  line-height: 1rem;
  font-size: .21rem;
  font-weight: 600;
  color: black;
  margin-right: .3rem;
  box-sizing: border-box;
  padding-top: .05rem;
}

.listBac .info_card_top div i{
  display: block;
  width: 0rem;
  height: .04rem;
  background-color: #ec414d;
  transition: all 0.7s;
}

.listBac .info_card_top a{
  display: block;
  width: 100%;
  height: .94rem;

  cursor: pointer;
  text-align: center;
  font-weight: 500;
  /*font-size: .2rem;*/
  color: #212121;
}

.listBac .info_card_top div:hover i{
  width: 100%;
}

.listBac .info_card_top input{
  width: 2.2rem;
  height: .35rem;
  color: #dcdcdc;
  margin-top: .4rem;
  margin-right: .17rem;
  padding-left: .1rem;
  border: .01rem solid #dcdcdc;
  border-radius: .05rem;
  font-size: .14rem;
}

.listBac .info_card_top button{
  width: 1rem;
  height: .35rem;
  margin-top: .4rem;
  font-size: .16rem;
  background-color: #ec414d;
  color: #ffffff;
  border-radius: .05rem;
}

.listBac .info_card_medium{
  width: 12.3rem;
  height: .45rem;
  padding-left: 1rem;
  background-color: #fdf9f8;
  margin-left: .5rem;
  margin-bottom: .45rem;
}

.listBac .info_card_medium div{
  float: left;
  height: .18rem;
  font-size: .16rem;
  margin-top: .1rem;
  /*margin-top: .1rem;*/
  margin-right: .1rem;
}

.listBac .info_card_medium i{
  display: block;
  float: left;
  margin-right: .9rem;
  margin-top: .11rem;
}

.listBac .list{
  width: 12.3rem;
  height: 1.9rem;
  margin-left: .5rem;
  padding-left: .5rem;
  /*background-color: pink;*/
}

.listBac .list div{
  float: left;
  width: 2.8rem;
  height: 1.9rem;
  margin-right: .15rem;
  background-image: url("../../../../images/background_work1.png");
  background-size: 100% 100%;
}

.listBac .list div:nth-of-type(3){
  background-image: url("../../../../images/background_work2.png");
}



</style>