<template>
  <div class="user_card">
    <div class="user_card_top">
      <div class="tx"></div>
      <div class="info">
        <p>用户1234</p>
        <p>完善账号信息</p>
        <button @click="$router.push('/PersonalInformation')">立即完善</button>
      </div>
    </div>
    <div class="user_card_bottom">
      <div class="real-name">
        <i>i</i>
        <p>尊敬的用户，请完成实名认证，得到高质量服务</p>
      </div>
      <div class="number">
        <p>参与项目</p>
        <div class="number_1">0</div>
        <p>发布项目</p>
        <div class="number_2">0</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserCardMini"
}
</script>

<style scoped>

.user_card{
  width: 14.5rem;
  height: 2.6rem;
  background-color: #ffffff;
  margin-left: .7rem;
  margin-bottom: .35rem;
}

.user_card_top{
  width: 14.5rem;
  height: 1.9rem;
  padding-top: .42rem;
  padding-left: .65rem;
  border-bottom: .01rem solid #acaeab;
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.user_card_top .tx{
  float: left;
  width: 1.02rem;
  height: 1.02rem;
  margin-right: .2rem;
  border-radius: 50%;
  background-image: url("../images/tx.png");
  background-size: 100% 100%;
}

.user_card_top .info{
  float: left;
  /* width: 1.34rem; */
  height: 1.02rem;
}

.user_card_top .info p:nth-of-type(1){
  font-size: .21rem;
  color: #212121;
  margin-bottom: .1rem;
}

.user_card_top .info p:nth-of-type(1)::after{
  content: url("../images/认证.png");
  width: .18rem;
  height: .18rem;
  margin-left: .05rem;
}

.user_card_top .info p:nth-of-type(2){
  float: left;
  height: 30px;
  font-size: .15rem;
  color: #acaeab;
  margin-right: 5px;
  margin-bottom: .15rem;
  line-height: 30px;
}

.user_card_top .info button{
  width: .9rem;
  height: .3rem;
  background-color: #ff645f;
  color: #ffffff;
  font-size: .12rem;
}

.user_card_bottom{
  width: 14.5rem;
  height: .45rem;
  background-color: #ffffff;
}

.user_card_bottom .real-name{
  float: left;
  /* width: 3.6rem; */
  height: .18rem;
  margin-left: .5rem;
  border: .01rem solid transparent;
  line-height: 18px;
}

.user_card_bottom .real-name i{
  display: block;
  float: left;
  width: .1rem;
  height: .1rem;
  line-height: .1rem;
  margin-right: .1rem;
  border-radius: 50%;
  background-color: #ec414d;
  text-align: center;
  color: #ffffff;
  margin-top: .03rem;
}


.user_card_bottom .real-name p{
  float: left;
  font-size: .12rem;
  color:#acaeab;
}

.user_card_bottom .number{
  float: right;
  height: .18rem;
}

.user_card_bottom .number p{
  float: left;
  font-size: .16rem;
  color: #212121;
  margin-right: .38rem;
}

.user_card_bottom .number div{
  float: left;
  font-size: .16rem;
  color: #ec414d;
  margin-right: .9rem;
}

</style>